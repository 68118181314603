<template>
  <div class="daily-composition">
    <div class="banner">
      <div class="bannerContainer">
        <div class="banner-head">
          <h1>同步写作300天</h1>
          <p class="banner-tip">每日一句，每周一段，每月一篇</p>
          <div class="banner-list">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/white-yes-icon.png" />
            <p>同步语文教材、贴合考纲</p>
          </div>
          <div class="banner-list">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/white-yes-icon.png" />
            <p>分年段、300节在线课程、26册配套实体教材</p>
          </div>
          <div class="banner-list">
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/white-yes-icon.png" />
            <p>每月1次一对一写作精点评、限时赠送4次周点评</p>
          </div>
          <span class="buy-btn" @click="openMask(4)">立即报名</span>
        </div>
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/blue-icon.png"
          alt="同步写作"
          class="composition-img"
        />
      </div>
    </div>
    <!-- 课程介绍 -->
    <div class="class-info">
      <div class="container">
        <div class="info">
          <!-- <div class="info-left">
            <img
              @click="videoPlay"
              v-if="showVideoBg"
              class="video-bg"
              src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/composition-videobg.jpg"
              alt
            />
            <video
              ref="dailyCompositionVideo"
              class="video-player"
              :class="{hide: showVideoBg}"
              controls
              name="media"
              controlslist="nodownload"
            >
              <source
                src="https://xcx.alicdn2.hexiaoxiang.com/web_static/zh_qzw.mp4"
                type="video/mp4"
              />
            </video>
          </div> -->
          <div class="info-right">
            <h5 class="info-title">课程介绍</h5>
            <p class="info-detail">
              河小象大语文携手北师大、浙大文学硕博士团队，设计了分年段的同步写作课程——精选教材知识点，贴合考纲内容，针对性提升写作力：
              课程共300节课，每课8分钟，包含5分钟讲课和3分钟训练；
              课程分年段设置，低段适合一二年级，中段针对三四年级。
              课程按好词、好句专题（低级）/写人、叙事、写景、状物等专题（高级）分类，区分和总结不同题材的写作技巧。
              课程设置了6大板块，5大练习，趣味学习和上升式练笔相结合。
            </p>
          </div>
        </div>
        <div class="new-style info">
          <div class="content">
            <div class="content-tit">写作上的“三座大山”</div>
            <p class="composition-tit">没话写</p>
            <p class="content-det">“抄写的好词好句一大堆，但是用不上啊！”</p>
            <p class="composition-tit">不愿写</p>
            <p class="content-det">“我家孩子最怕写作文了，写作这件事太枯燥了！”</p>
            <p class="composition-tit">随便写</p>
            <p class="content-det">“孩子写作毫无章法，想到什么写什么，还容易离题。”</p>
          </div>
          <img
            class="img-box"
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/compositon1.png"
          />
        </div>
        <div class="class-resule info">
          <img
            class="img-box img-left"
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/compositon2.png"
          />
          <div class="content">
            <div class="content-tit">课程效果</div>
            <p class="content-det">原创动画情景剧导入；</p>
            <p class="content-det">延伸剧情讲解素材和技巧；</p>
            <p class="content-det">每课设置多个互动环节；</p>
            <p class="content-det">从中外名著中鳞选的与主题相关的句段；</p>
            <p class="content-det">课后经典诵读提升语感；</p>
            <p class="content-det">课后摘抄好词好句；</p>
          </div>
        </div>
        <div class="ai-mark info">
          <div class="content content-center">
            <div class="content-tit">AI智能诵读打分</div>
            <p class="content-det">我们从完整度、流利度声韵母、流利度、声调，四个方面检测对孩子的诵读进行评分，让孩子更有目的性的诵读训练。</p>
          </div>
          <img
            class="img-box"
            src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/composition3.png"
          />
        </div>
      </div>
    </div>
    <!-- 课程安排 -->
    <div class="schedule">
      <div class="container">
        <h5 class="title">
          课程
          <span>安排</span>（部分）
        </h5>
        <!-- <div class="table-head table-item">
          <span class="first">课时</span>
          <span class="sec">选自</span>
          <span class="third">课程</span>
        </div>
        <div class="table-body">
          <div class="table-item">
            <span class="first">第一讲</span>
            <span class="sec">写人脸色</span>
            <span class="third">《回家过大年》</span>
          </div>
          <div class="table-item">
            <span class="first">第二讲</span>
            <span class="sec">写人的鼻子</span>
            <span class="third">《整形记》</span>
          </div>
          <div class="table-item">
            <span class="first">第三讲</span>
            <span class="sec">描写叙事地点</span>
            <span class="third">《法国旅行记》</span>
          </div>
          <div class="table-item">
            <span class="first">第四讲</span>
            <span class="sec">描写叙事时间</span>
            <span class="third">《最美妙的时刻》</span>
          </div>
          <div class="table-item">
            <span class="first">第五讲</span>
            <span class="sec">由远及近的写景顺序</span>
            <span class="third">《台北看画展》</span>
          </div>
          <div class="table-item">
            <span class="first">第六讲</span>
            <span class="sec">描写植物</span>
            <span class="third">《z植物园奇观》</span>
          </div>
          <div class="table-item">
            <span class="first">第七讲</span>
            <span class="sec">信封正确书写方式</span>
            <span class="third">《等不到的回信》</span>
          </div>
        </div>
        <div class="table-sum">共365讲</div> -->
        <compositionArrage></compositionArrage>
      </div>
    </div>
    <!-- 配套教材 -->
    <div class="book">
      <div class="book-contain">
        <h2>配套教材</h2>
        <div class="book-btn">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/write-text-btn1.png" >
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/write-text-btn2.png" >
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/write-text-btn3.png" >
        </div>
       <div class="book-img">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/write-text-book1.png" >
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/write-text-book2.png" >
       </div>
      </div>
    </div>
    <!-- 上课流程 -->
    <div class="process">
      <h5 class="title">
        上课
        <span>流程</span>
      </h5>
      <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/process.png" alt="上课流程" />
    </div>
    <!-- 我们承诺 -->
    <div class="commitment">
      <img
        src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/commitment-grey-blue.png"
        alt="服务承诺"
      />
    </div>
    <!-- 家长反馈 -->
    <feedBack
      postImg="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/fb-composition.png"
      :list="feedBack"
      rgba="color: #47ACFF"
      title="true"
      class="feedback"
    ></feedBack>
    <quickTool :downloadShow="showData"></quickTool>
    <!-- 弹窗 -->
    <enterMask :showMask="open" :imgUrl="url" @onClose="open=false"></enterMask>
  </div>
</template>

<script>
import feedBack from "../components/feedBack";
import quickTool from "@/components/quickTool";
import enterMask from "@/components/enterMask";
import compositionArrage from '@/components/compositionArrage'
export default {
  name: "dailyComposition",
  components: { feedBack, quickTool, enterMask,compositionArrage },
  data() {
    return {
      open: false, //弹窗弹出
      url: "", //二维码地址
      showData: false,
      feedBack: [
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback1.png",
          name: "王诺彦",
          grade: "一年级",
          desc:
            "平均三天上墙一次，现在一下子语文成绩很好了，前三名了，谢谢老师！"
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback2.png",
          name: "珑月",
          grade: "一年级",
          desc:
            "我女儿读了葛朗台的人物描写，为了想要得高分读了好几遍，最后都背下来了！"
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback3.png",
          name: "薇笑",
          grade: "三年级",
          desc: "通过7天的学习，孩子的确有收获，我和他都很喜欢轻作文课！"
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback4.png",
          name: "龚苹",
          grade: "二年级",
          desc: "这个课真的很好，老师的知识量非常大，我家娃很喜欢！"
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback5.png",
          name: "唐诗涵",
          grade: "一年级",
          desc:
            "今天的课她读了好多遍都读不好，我都不耐烦了她还不死心。她真的是超爱轻作文这个环节的，希望孩子能一直坚持下去。"
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback6.png",
          name: "汶汶",
          grade: "一年级",
          desc:
            "本来以为效果一般，结果我考了一下孩子，发现他记住的真不少！期待之后的效果！"
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback7.png",
          name: "余霞",
          grade: "三年级",
          desc:
            "现在学到第二本了，孩子会记住上课时间，主动学习、交作业，尤其是答题环节，孩子特别喜欢！"
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback8.png",
          name: "小脚",
          grade: "一年级",
          desc:
            "孩子特别喜欢河小象的课程，每天都吵着要上年糕姐姐的课，十分感谢河小象！"
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback9.png",
          name: "安然",
          grade: "二年级",
          desc:
            "最近孩子的写作水平进步特别大，看了他的试卷之后简直出乎我的意料，连班主任都夸奖他。"
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback10.png",
          name: "全",
          grade: "一年级",
          desc:
            "孩子以前读的书有拼音，孩子依赖拼音不看汉字。轻作文的课很适合一年级的孩子，便于孩子词汇量增大。"
        }
      ],
      showVideoBg: true
    };
  },
  created() {},
  mounted() {},
  watch: {
    $route(to, from) {
      this.open = false;
    }
  },
  methods: {
    // 播放视频
    videoPlay() {
      this.showVideoBg = false;
      let video = this.$refs.dailyCompositionVideo;
      let that = this;
      video
        .play()
        .then(res => {
          video.addEventListener("pause", () => {
            that.showVideoBg = true;
          });
          video.addEventListener('seeking',()=>{
            that.showVideoBg=false
          })
        })
        .catch(err => {});
    },
    openMask(flag) {
      if (flag === 4) {
        this.open = true;
        this.url =
          "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/enter-componsition.png";
      }
    }
  }
};
</script>

<style scoped>
.daily-composition {
  width: 100%;
  min-width: 1120px;
  text-align: left;
}
.banner {
  width: 100%;
  max-width: 1920px;
  max-height: 640px;
  margin: 0 auto;
  height: 640px;
  background: url("https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/blue-bg.png")
    no-repeat;
  background-size: 100% 640px;
  display: flex;
}
.banner-head h1 {
  font-size: 54px;
  font-weight: bold;
  color: #ffffff;
}
.banner-head .banner-tip {
  font-size: 32px;
  color: #fff;
  margin-top: 14px;
  position: relative;
  margin-bottom: 64px;
  width: 562px;
}
.banner-head .banner-tip::after {
  content: "";
  width: 90px;
  height: 4px;
  border-radius: 2px;
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 62px;
}
.banner-list {
  display: flex;
  color: #fff;
  align-items: center;
}
.banner-list p {
  line-height: 40px;
  font-size: 18px;
}
.banner-list img {
  object-fit: contain;
  margin-right: 10px;
  height: 18px;
}
.daily-composition .buy-btn {
  margin-top: 80px;
  cursor: pointer;
  width: 240px;
  height: 60px;
  background: linear-gradient(
    270deg,
    rgba(250, 114, 24, 1) 0%,
    rgba(254, 143, 62, 1) 100%
  );
  border-radius: 30px;
  font-size: 26px;
  color: #ffffff;
  font-weight: bold;
  display: block;
  text-align: center;
  line-height: 60px;
}
.book{
  background: #56C1FF;
  padding: 80px 0 72px;
}
.book-contain{
  width: 1140px;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}
.book-contain h2{
 font-size:50px;
font-family:PingFangSC-Medium;
font-weight:500;
color: #fff;
margin-bottom: 16px;
}
.book-btn {
  margin-bottom: 62px;
}
.book-btn img:not(:last-child){
  margin-right: 24px;
}
.book-img{
display: flex;
justify-content: space-between;
width: 100%;
}
.container {
  width: 1120px;
  padding: 0 20px;
  box-sizing: border-box;
  margin: 0 auto;
}
/* .class-info {
  padding: 88px 0;
} */
.info {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
}
.info-left {
  width: 320px;
  height: 600px;
  margin-right: 150px;
}
.video-player {
  width: 100%;
}
.video-bg {
  width: 320px;
  height: 556px;
}
.hide {
  display: none;
}
.info-right {
  flex: 1;
}
.info-title {
  font-size: 50px;
  font-family: MicrosoftYaHei-Bold;
  font-weight: bold;
  color: rgba(68, 65, 68, 1);
  margin-bottom: 50px;
  padding-top: 110px;
}
.info-detail {
  font-size: 20px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(68, 65, 68, 1);
  line-height: 40px;
}
.img-box {
  margin-top: 25px;
  object-fit: cover;
}
.img-left {
  margin: 74px 196px 136px 53px;
}
.content {
  max-width: 543px;
}
.content-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.class-resule .content {
  margin-top: 92px;
}
.new-style .content {
  margin-top: 76px;
}
.content-tit {
  font-size: 36px;
  font-family: MicrosoftYaHei-Bold;
  font-weight: bold;
  color: rgba(68, 65, 68, 1);
  margin-bottom: 26px;
}
.composition-tit {
  color: #2fa4ff;
  font-size: 24px;
}
.content-det {
  font-size: 20px;
  font-family: MicrosoftYaHei;
  font-weight: 400;
  color: rgba(68, 65, 68, 1);
  line-height: 40px;
}
.class-resule .img-box {
  margin-right: 65px;
}
.new-style .img-box,
.ai-mark .img-box {
  margin-left: 65px;
}
.schedule {
  background: rgba(246, 249, 249);
  padding: 73px 0 52px;
}
h5.title {
  font-size: 50px;
  font-family: MicrosoftYaHei-Bold;
  font-weight: bold;
  color: rgba(68, 65, 68, 1);
  text-align: center;
  margin-bottom: 60px;
}
h5.title > span {
  color: #47acff;
}
.table-head span {
  background: #56c1ff;
  color: #fff;
  font-size: 26px;
  font-weight: bold;
}
.table-item {
  display: flex;
  justify-content: space-between;
}
.table-item span {
  display: block;
  height: 80px;
  line-height: 80px;
  text-align: center;
  color: rgba(51, 51, 51, 1);
  margin-bottom: 8px;
  font-size: 24px;
}
span.first {
  width: 310px;
  border-radius: 10px 0px 0px 10px;
}
span.sec {
  width: 424px;
}
span.third {
  width: 370px;
  border-radius: 0px 10px 10px 0px;
}
.table-body > .table-item:nth-child(2n-1) span {
  background: #ecf9ff;
}
.table-body .table-item:nth-child(2n) span {
  background: #d5f2ff;
}
.table-sum {
  width: 100%;
  height: 80px;
  background: #56c1ff;
  border-radius: 10px;
  line-height: 80px;
  text-align: center;
  color: #fff;
  font-size: 26px;
  font-weight: bold;
}
.process img {
  display: block;
  width: 859px;
  margin: 0 auto;
  height: 664px;
}
.process h5 {
  padding-top: 70px;
}
.commitment {
  position: relative;
  height: 684px;
  background: rgba(246, 245, 250);
}
.commitment img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  height: 660px;
  margin: 0 auto;
  z-index: 1;
  width: 100%;
  object-fit: cover;
}
.commitment h5 {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
  margin-bottom: 60px;
  padding-top: 60px;
}
.feedback {
  overflow: hidden;
}
.composition-img {
  width: 530px;
  object-fit: cover;
}
.bannerContainer {
  width: 1120px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
  align-items: center;
}
</style>
