<template>
  <div class="course-arrage">
    <ul class="course-tab">
      <li @click="cur=0" :class="{active:cur==0}">
        <div class="tab-name">低段</div>
        <div class="tab-tip">适合一、二年级</div>
      </li>
      <li @click="cur=1" :class="{active:cur==1}">
        <div class="tab-name">中高段</div>
        <div class="tab-tip">适合三、四、五年级</div>
      </li>
    </ul>
    <div class="tab-content">
      <div class="list-header">
        <div class="schedule">课时</div>
        <div class="classic">选自</div>
        <div class="knowledge">课程</div>
      </div>
      <transition name="slide-fade">
        <div v-show="cur==0">
          <div class="list-body">
            <div
              class="list-box"
              v-for="(item,index) in primaryCourse"
              :key="index"
              v-show="isOpen||index<max"
            >
              <div class="schedule">
                <p v-for="(it,inx) in item.week" :key="inx">{{it}}</p>
              </div>
              <div class="classic">
                <p>{{item.classic}}</p>
              </div>
              <div class="knowledge">
                <p v-for="(it,inx) in item.knowledge" :key="inx">{{it}}</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <transition name="slide-fade">
        <div v-show="cur==1">
          <div class="list-body">
            <div
              class="list-box"
              v-for="(item,index) in midCourse"
              :key="index"
              v-show="isOpen||index<max"
            >
              <div class="schedule">
                <p v-for="(it,inx) in item.week" :key="inx">{{it}}</p>
              </div>
              <div class="classic">
                <p>{{item.classic}}</p>
              </div>
              <div class="knowledge">
                <p v-for="(it,inx) in item.knowledge" :key="inx">{{it}}</p>
              </div>
            </div>
          </div>
        </div>
      </transition>
      <!-- <button class="look-all" v-show=" midCourse.length>max" @click="isOpen=!isOpen">
        <span>{{!isOpen?'点击查看全部':'收起'}}</span>
      </button> -->
      <!-- <button class="look-all">收起</button> -->
       <button class="look-course" @click="openmask">报名查看更多课程</button>
    </div>
         <!-- 弹窗 -->
    <enterMask :showMask="open" :imgUrl="url" @onClose="open=false"></enterMask>
  </div>
</template>
<script>
import enterMask from "@/components/enterMask";
export default {
  data() {
    return {
      cur: 0,
      max: 3,
      isOpen: true,
      open:false,
      url:'',
      primaryCourse: [
        {
          week: ["第一课"],
          classic: "《帮吉吉鼠搬家》",
          knowledge: ["量词"]
        },
        {
          week: ["第二课"],
          classic: "《春天的田野》",
          knowledge: ["带颜色的成语"]
        },
        {
          week: ["第三课"],
          classic: "《快乐运动会》  ",
          knowledge: ["人物动作描写  "]
        },
        {
          week: ["第四课"],
          classic: "《绿色的太阳》",
          knowledge: ["反问句"]
        },
        {
          week: ["第五课"],
          classic: "《秋游的乐趣》   ",
          knowledge: ["描写季节的成语 "]
        },
        {
          week: ["第六课"],
          classic: "《陪懒懒猪买衣服》",
          knowledge: ["“的”的用法  "]
        },
        {
          week: ["第七课"],
          classic: "《我们去野炊吧》",
          knowledge: [" 第一周总结课"]
        },
        {
          week: ["第八课"],
          classic: "《你会找不同吗》",
          knowledge: [" 有趣的反义词  "]
        },
         {
          week: ["第九课"],
          classic: "《一次数学考试过后》",
          knowledge: ["人物心理描写"]
        },
        {
          week: ["第十课"],
          classic: "《一起去购物》",
          knowledge: ["动词"]
        },
        {
          week: ["第十一课"],
          classic: "《我们不一样》",
          knowledge: ["因为……所以……"]
        },
        {
          week: ["第十二课"],
          classic: "《森林公园怎么走》",
          knowledge: ["什么是方位词"]
        },
        {
          week: ["第十三课"],
          classic: "《我怎么不知道下雪了》",
          knowledge: ["常用的标点符号"]
        },
         {
          week: ["第十四课"],
          classic: "《登山历险记》",
          knowledge: ["第二周总结课 "]
        },
      ],
      midCourse: [
        {
          week: ["第一课"],
          classic: "《爸爸抓小偷》",
          knowledge: ["如何描写人的行为"]
        },
        {
          week: ["第二课"],
          classic: "《帮妈妈洗菜》",
          knowledge: ["如何生动地写出人物的动作"]
        },
        {
          week: ["第三课"],
          classic: "《肚子装不下了》",
          knowledge: ["如何写一个贪吃鬼"]
        },
        {
          week: ["第四课"],
          classic: "《香香的大餐》",
          knowledge: ["如何写一个小书迷"]
        },
        {
          week: ["第五课"],
          classic: "《我的海报不见了！》",
          knowledge: ["如何写一个满头大汗的人"]
        },
        {
          week: ["第六课"],
          classic: "《爷爷的钥匙去哪儿了？》",
          knowledge: ["如何写一个健忘的人"]
        },
        {
          week: ["第七课"],
          classic: "《他在做什么？》",
          knowledge: ["第一周总结课：写一写人物动作"]
        },
        {
          week: ["第八课"],
          classic: "《看望爷爷》",
          knowledge: ["如何描写人物的性格"]
        },
        {
          week: ["第九课"],
          classic: "《哪吒今天烧坏了什么？》",
          knowledge: ["如何写一个调皮的人"]
        },
        {
          week: ["第十课"],
          classic: "《一只鸡蛋引发的争论》",
          knowledge: ["如何写一个口齿伶俐的人"]
        },
         {
          week: ["第十一课"],
          classic: "《大嗓门的志愿者阿姨》",
          knowledge: ["如何写一个热心的人"]
        },
        {
          week: ["第十二课"],
          classic: "《与小机灵鬼的初次见面》",
          knowledge: ["如何写一个机灵的人"]
        },
        {
          week: ["第十三课"],
          classic: "《他是怎样的性格？》",
          knowledge: ["第二周总结课：写一写人物性格"]
        },
        {
          week: ["第十四课"],
          classic: "《香香的困惑》",
          knowledge: ["口语交际：如何向别人介绍自己"]
        },
      ]
    };
  },
  components:{
enterMask
  },
  methods: {
    openmask(){
       this.open = true;
        this.url =
          "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/enter-componsition.png";
    }
  }
};
</script>

<style lang="scss" scoped>
.course-arrage {
  padding: 0 20px;
  width: 1120px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  .course-tab {
    list-style: none;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 47px 0 40px;
    padding: 0;
    li {
      width: 530px;
      height: 110px;
      color: #56c1ff;
      border-radius: 10px;
      box-sizing: border-box;
      border: 2px solid #56c1ff;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &:hover {
        background-color: #ecf9ff;
      }
      .tab-name {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .tab-tip {
        font-size: 14px;
      }
      &.active {
        border: 0;
        background-color: #56c1ff;
        color: #fff;
      }
    }
  }
  .tab-content {
    width: 100%;
    color: #fff;
    .list-header {
      display: flex;
      div {
        background-color: #56c1ff;
        font-size: 20px;
        font-weight: bold;
        min-height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
    .schedule {
      width: 300px;
    }
    .knowledge {
      width: 500px;
      text-align: center;
      p {
        width: 100%;
        padding: 0px 26px;
        box-sizing: border-box;
      }
    }
    .power {
      width: 500px;
    }
    .list-body {
      width: 100%;
      .list-box {
        display: flex;
        &:nth-child(2n + 1) {
          div {
            background-color: #ecf9ff;
          }
        }
        div {
          background-color: #d5f2ff;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #333333;
          font-size: 16px;
          line-height: 24px;
          box-sizing: border-box;
          padding: 12px 26px;
        }
      }
    }
    .classic {
      width: 280px;
      justify-content: center;
      word-break: break-all;
      text-align: center;
      p {
        width: 254px;
        padding: 0px 26px;
        box-sizing: border-box;
      }
    }
    .look-all {
      width: 100%;
      height: 60px;
      background-color: #ff8f63;
      border-radius: 10px;
      color: #fff;
      cursor: pointer;
      border: 0;
      outline: 0;
      font-size: 20px;
      margin-top: 40px;
    }
  }
    .look-course{
    cursor: pointer;
    width: 313px;
    height: 60px;
    background: -webkit-gradient(
 linear,
 right top, left top,
 from(rgba(250, 114, 24, 1)),
 to(rgba(254, 143, 62, 1))
 );
    background: linear-gradient(
 270deg,
 rgba(250, 114, 24, 1) 0%,
 rgba(254, 143, 62, 1) 100%
 );
    border-radius: 30px;
    font-size: 26px;
    color: #ffffff;
    font-weight: bold;
    display: block;
    text-align: center;
    line-height: 60px;
    margin: 68px auto;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
